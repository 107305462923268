// resources/js/partials/breadcrumb.js

class BreadcrumbModifier {
    constructor(elementId) {
        // Get the breadcrumb element by its ID
        this.breadcrumbElement = document.getElementById(elementId);

        // Check if the breadcrumb element exists on the page
        if (this.breadcrumbElement) {
            // Process the breadcrumb text
            this.processBreadcrumb();
        } else {
            console.warn(`Element with ID '${elementId}' not found on the page.`);
        }
    }

    // Method to process the breadcrumb text
    processBreadcrumb() {
        // Get all breadcrumb links within the element
        const breadcrumbLinks = this.breadcrumbElement.querySelectorAll('a');

        // Iterate over each breadcrumb link
        breadcrumbLinks.forEach(link => {
            // Modify the text content of each link
            link.textContent = this.modifyText(link.textContent);
        });
    }

    // Method to modify the text: convert to uppercase (first letter of each word) and remove dashes
    modifyText(text) {
        // Remove dashes and split the text into words
        const words = text.replace(/-/g, ' ')
            .split(' ');

        // Convert the first letter of each word to uppercase
        const capitalizedWords = words.map(word => {
            return word.charAt(0)
                .toUpperCase() + word.slice(1)
                .toLowerCase();
        });

        // Join the words back into a single string and return
        return capitalizedWords.join(' ');
    }
}

// Instantiate the BreadcrumbModifier with the ID of the breadcrumb element
new BreadcrumbModifier('Breadcrumb');
