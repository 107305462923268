// resources/js/partials/nav.js

//this is for hide menu when you click away or on another menu item
document.addEventListener('click', function (event) {

    const { target } = event;
    const checkboxes = document.querySelectorAll('.toggle-input');
    let isClickInsideMenu = false;

    checkboxes.forEach((checkbox) => {
        const parent = checkbox.closest('.toggleable');
        if (parent.contains(target)) {
            isClickInsideMenu = true;
            if (target.matches('.toggle-input')) {
                checkboxes.forEach(cb => {
                    if (cb !== target) {
                        cb.checked = false;
                    }
                });
            }
        }
    });

    if (!isClickInsideMenu) {
        checkboxes.forEach((checkbox) => {
            checkbox.checked = false;
        });
    }
});











